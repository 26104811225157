import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        country: '',
    },
    mutations: {
        setCard(state, value) {
            state.country = value;
        }
    }
});

export default store;